<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card
          rounded
          class="mx-auto ems-opacity-50-bg frosted-glass"
          elevation="0"
          height="100%"
          width="100%"
        >
          <v-card-text v-if="loading" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-text>
          <v-card-text v-else-if="classroom">
            <v-row>
              <v-col cols="12">
                <ClassRelayControl
                  class="mb-1"
                  :classroom="classroom"
                  :loadingSetRelay="relayLoading"
                  :phase2Loading="phase2Loading"
                  @setRelayControllable="relayControllable = $event"
                  @setRelayLoading="relayLoading = $event"
                  @setPhase2Loading="phase2Loading = $event"
                  :operatingAc="hasOperatingAc"
                />
              </v-col>
              <v-col v-if="cardUnavaliable" class="error--text mt-2 px-4">
                <div>*未插卡</div>
              </v-col>
              <v-col
                v-else-if="acControllable"
                v-for="ac in mixAirConditioners"
                :key="ac.id"
                cols="12"
                md="6"
              >
                <div
                  class="
                    ems-pale-blue
                    pa-3
                    d-flex
                    align-center
                    justify-space-between
                    font-weight-bold
                    accent--text
                  "
                >
                  <span>
                    {{ ac.name }}
                    <span class="ml-2 text-caption"
                      >{{ ac.serialNumber || 'null' }} |
                      {{ ac.modbusId || 'null' }}</span
                    >
                  </span>
                  <!-- <div
                    class="d-flex align-center justify-end mr-3"
                    v-if="!ac.status"
                  >
                    <div class="ml-2 white--text">無冷氣資料</div>
                  </div> -->
                  <div class="d-flex align-center justify-end mr-3">
                    <div class="d-flex align-center justify-center">
                      <v-img
                        v-if="ac.type === 'IR_AIRCONDITIONER'"
                        contain
                        max-height="18"
                        max-width="18"
                        :src="require('@/assets/image/acControl/ir.png')"
                      ></v-img>
                      <v-icon
                        v-else
                        class="mx-1"
                        x-small
                        :color="ac.status === 'POWER_ON' ? 'success' : 'error'"
                      >
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </div>
                    <div class="mx-2 grey--text">
                      <span v-if="ac.type === 'IR_AIRCONDITIONER'">
                        IR控制
                      </span>
                      <span v-else-if="ac.status === 'POWER_OFF'"> 關閉 </span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="mt-2 text-caption grey--text">
                  更新時間<br />
                  {{ moment(ac.ts).format('YYYY-MM-DD HH:mm:ss') }} |
                  {{ ac.deviceAlive }}
                </div>
                <!-- <div v-if="!ac.realtimeAcItem">
                  <div class="grey--text text-center mt-3 pa-3">無冷氣資料</div>
                </div> -->
                <div class="py-3">
                  <v-row>
                    <v-col cols="12">
                      <template v-if="ac.type === 'IR_AIRCONDITIONER'">
                        <IRControl :controlId="ac.id" />
                      </template>
                      <template v-else>
                        <ACControl
                          :ac="ac"
                          :classroomId="tagId"
                          @operateAc="operateAc"
                        />
                      </template>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else class="text-center"> 無資料</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ACControl from '@/components/Action/ACControl'
import ClassRelayControl from '@/components/Action/ClassRelayControl'
import IRControl from '@/components/Action/IRControl'

export default {
  components: {
    ACControl,
    ClassRelayControl,
    IRControl
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 預設有兩台
      controlItem: {},
      relayControllable: false,
      relayLoading: false,
      phase2Loading: false,
      operatingAcList: []
    }
  },
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption',
      classrooms: 'action/classrooms',
      loading: 'action/loadingClassrooms'
    }),
    classroom() {
      return this.classrooms[0]
    },
    search() {
      return this.$route.query.search || ''
    },
    acControllable() {
      return (
        this.relayControllable &&
        !this.relayLoading &&
        this.classroom &&
        this.classroom?.cardReaders?.length &&
        this.classroom.cardReaders[0].relay?.mode !== 0
      )
    },
    hasOperatingAc() {
      return this.operatingAcList.length > 0
    },
    cardUnavaliable() {
      return (
        this.classroom &&
        this.classroom?.cardReaders?.length &&
        this.classroom?.cardReaders[0].relay?.status === 0 &&
        this.classroom?.cardReaders[0].relay?.mode === 2
      )
    },
    mixAirConditioners() {
      return []
        .concat(
          this.classroom.irAirConditioners.map((ac) => ({
            ...ac,
            type: 'IR_AIRCONDITIONER'
          }))
        )
        .concat(
          this.classroom.airConditioners.map((ac) => ({
            ...ac,
            type: 'AIRCONDITIONER'
          }))
        )
    }
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      closeMsg: 'snackbar/close',
      getClassroomInfo: 'action/getClassroomInfo'
    }),
    operateAc(payload) {
      if (payload.status)
        this.operatingAcList = this.operatingAcList.concat([payload.id])
      else
        this.operatingAcList = this.operatingAcList.filter(
          (id) => payload.id !== id
        )
    }
  },
  mounted() {
    this.getClassroomInfo(this.tagId)
  }
}
</script>

<style scoped lang="scss">
>>> .v-slider__tick {
  border-radius: 50%;
}
>>> .v-slider__tick:not(.v-slider__tick--filled) {
  background: rgb(25 118 210 / 35%);
}
.AC-slider {
  width: 100%;
  // max-width: 100px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}
.ac-expansion-panel {
  border: 1px solid transparent;
  &.active {
    border: 1px solid var(--v-primary-base);
  }
}
</style>
